<template>
  <v-container class="my-8">
    <v-row class="mb-4">
      <p
        style="
          padding: 0;
          margin: 0;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 16px;
          color: #343235;
        "
      >
        {{ $t("custom.notifications") }}
      </p>
    </v-row>
    <v-row class="mb-4">
      <Warehouse />
    </v-row>
    <!-- <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <v-subheader style="font-weight: 700">{{
        $t("account.get_notifications")
      }}</v-subheader>
      <v-btn color="accent" outlined small class="mx-3">{{
        $t("account.mark_as_read")
      }}</v-btn>
    </div> -->
    <div v-if="this.$store.state.lang == 'en'">
      <div v-for="(item, index) in notfications" :key="index">
        <v-list-item style="padding: 0">
          <v-list-item-content>
            <p style="font-weight: bold" class="my-2">
              {{ item.data.content.en }}
            </p>
            <!-- <v-list-item-subtitle
              v-html="item.data.body.en"
            ></v-list-item-subtitle> -->
            <v-list-item-subtitle class="my-2">{{
              new Date(item.created_at).toLocaleDateString()
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </div>

    <div v-if="this.$store.state.lang == 'ar'">
      <div v-for="(item, index) in notfications" :key="index">
        <v-list-item style="padding: 0">
          <v-list-item-content>
            <p style="font-weight: bold" class="my-2">
              {{ item.data.content.ar }}
            </p>
            <!-- <v-list-item-subtitle
              v-html="item.data.body.ar"
            ></v-list-item-subtitle> -->
            <v-list-item-subtitle class="my-2">{{
              new Date(item.created_at).toLocaleDateString()
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </div>
  </v-container>
</template>

<script>
import Warehouse from "@/components/Warehouse.vue";
import { mapState } from "vuex";

export default {
  components: {
    Warehouse,
  },
  computed: mapState({
    notfications: (state) => state.notfications,
  }),
  created() {
    this.checkPendingList();
  },
  methods: {
    timeDifference(current, previous) {
      var msPerMinute = 60;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;

      var elapsed = current - previous;

      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + " seconds ago";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " minutes ago";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " hours ago";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " days ago";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " months ago";
      } else {
        return Math.round(elapsed / msPerYear) + " years ago";
      }
    },
    checkPendingList() {
      this.$http.get("/received/warba_pending_list").then(({ data }) => {
        let number = 0;
        number += data.orders.length;
        number += data.buy4me.length;
        this.postPaymentCount(number);
      });
    },
    postPaymentCount(count) {
      if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.pending_payments_count != undefined) {
          console.log("2");
          window.webkit.messageHandlers.pending_payments_count.postMessage(
            count
          );
        }
      }
      if (window.pending_payments_count != undefined) {
        window.pending_payments_count.postMessage(count);
      }
    },
  },
};
</script>
