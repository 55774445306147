<template>
  <v-select
    return-object
    v-model="warehouse"
    :items="stores"
    item-value="store_id"
    item-text="text"
    :label="$t('account.warehouse')"
    outlined
    @change="storeChanged"
    hide-details="true"
    class="mb-4"
  >
    <template slot="selection" slot-scope="data">
      <img
        v-if="data.item.store_id == 2"
        width="32"
        src="../assets/Icons/uk.svg"
        alt="usa"
        class="mx-1"
      />
      <img
        v-if="data.item.store_id == 1"
        width="32"
        src="../assets/Icons/us.svg"
        alt="usa"
        class="mx-1"
      />
      <span v-if="$i18n.locale == 'ar'">{{ data.item.text_ar }}</span>
      <span v-if="$i18n.locale == 'en'">{{ data.item.text_en }}</span>
    </template>
    <template slot="item" slot-scope="data">
      <img
        v-if="data.item.store_id == 2"
        width="32"
        src="../assets/Icons/uk.svg"
        alt="usa"
        class="mx-1"
      />
      <img
        v-if="data.item.store_id == 1"
        width="32"
        src="../assets/Icons/us.svg"
        alt="usa"
        class="mx-1"
      />
      <span v-if="$i18n.locale == 'ar'">{{ data.item.text_ar }}</span>
      <span v-if="$i18n.locale == 'en'">{{ data.item.text_en }}</span>
    </template>
  </v-select>
</template>

<script>
export default {
  data: () => ({
    warehouse: null,
    stores: [
      {
        store_id: 1,
        text_en: "USA Warehouse",
        text_ar: "المستودع الامريكي",
      },
      {
        store_id: 2,
        text_en: "UK Warehouse",
        text_ar: "المستودع البريطاني",
      },
    ],
  }),

  created() {
    this.setStore();
  },
  methods: {
    setStore() {
      return (this.warehouse = this.$store.state.store_id);
    },

    storeChanged() {
      return this.$store.dispatch("setStore", this.warehouse);
    },
  },
};
</script>

<style>
</style>
