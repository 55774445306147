import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Invoices from "../views/Invoices.vue";
import Shipped from "../views/Shipped.vue";
import Unauthorized from "../views/Unauthorized.vue";
import Notfications from "../views/Notfications.vue";
import Order from "../views/Order.vue";
import TrackOrder from "../views/TrackOrder.vue";
import Invoice from "../views/Invoice.vue";
import B4me from "../views/B4me/B4me.vue";
import Create from "../views/B4me/Create.vue";
import Details from "../views/B4me/Details.vue";
import Edit from "../views/B4me/Edit.vue";
import Pay from "../views/B4me/Pay.vue";
import Track from "../views/B4me/Track.vue";
import Account from "../views/Account.vue";
import Address from "../views/Address.vue";
import Calculator from "../views/Calculator.vue";
import Faq from "../views/Faq.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    meta: { requiresAuth: true },
  },
    {
    path: "/invoices/:id",
    name: "Order Invoice",
    component: Invoice,
    meta: { requiresAuth: true },
  },
    {
    path: "/shipped",
    name: "Shipped",
    component: Shipped,
    meta: { requiresAuth: true },
  },

  {
    path: "/shipped/order/:id",
    name: "Order",
    component: Order,
    meta: { requiresAuth: true },
  },
    {
    path: "/shipped/order/track/:id",
    name: "Order Tracking",
    component: TrackOrder,
    meta: { requiresAuth: true },
  },

  {
    path: "/notfications",
    name: "Notfications",
    component: Notfications,
    meta: { requiresAuth: true },
  },
    {
    path: "/calculator",
    name: "Calculator",
    component: Calculator,
    meta: { requiresAuth: true },
  },
   {
    path: "/Faq",
    name: "Faq",
    component: Faq,
    meta: { requiresAuth: true },
  },
  {
    path: "/b4me",
    name: "B4me",
    component: B4me,
    meta: { requiresAuth: true },
  },

    {
    path: "/b4me/create",
    name: "Create B4me",
    component: Create,
    meta: { requiresAuth: true },
  },

      {
    path: "/b4me/:id",
    name: "Details B4me",
    component: Details,
    meta: { requiresAuth: true },
  },

        {
    path: "/b4me/edit/:id",
    name: "Edit B4me",
    component: Edit,
    meta: { requiresAuth: true },
  },

        {
    path: "/b4me/pay/:id",
    name: "Pay B4me",
    component: Pay,
    meta: { requiresAuth: true },
  },
          {
    path: "/b4me/track/:id",
    name: "Track B4me",
    component: Track,
    meta: { requiresAuth: true },
  },

    {
    path: "/account",
    name: "Account",
    component: Account,
    meta: { requiresAuth: true },
  },

      {
    path: "/address",
    name: "Address",
    component: Address,
    meta: { requiresAuth: true },
  },

  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
    meta: { requiresVisitor: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
