<template>
  <div class="home">
    <v-container class="my-8">
      <v-row class="mb-4">
        <p style="font-weight: 700; font-size: 14px; padding: 0; margin: 0">
          {{ $t("b4me.order_tracking") }} #{{ this.$route.params.id }}
        </p>
      </v-row>
      <v-row class="mb-8">
        <v-col>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr role="row">
                  <th class="text-center">#</th>
                  <th class="text-center">{{ $t("account.status") }}</th>
                  <th class="text-center">
                    {{ $t("account.tracking_description") }}
                  </th>
                  <th class="text-center">{{ $t("account.track_number") }}</th>
                  <th class="text-center">{{ $t("account.tracking_url") }}</th>
                  <th class="text-center">{{ $t("account.courier") }}</th>
                  <th class="text-center">{{ $t("account.date") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(track, i) in tracking.tracking" :key="i">
                  <td class="text-center">{{ i + 1 }}</td>
                  <td class="text-center">
                    {{ track.status }}
                  </td>
                  <td class="text-center">
                    {{ track.description }}
                  </td>
                  <td class="text-center">
                    {{ track.tracking_number ? track.tracking_number : "N/A" }}
                  </td>
                  <td class="text-center">
                    {{ track.url ? track.url : "N/A" }}
                  </td>
                  <td class="text-center">
                    {{ track.courier ? track.courier : "N/A" }}
                  </td>
                  <td class="text-center">
                    {{ new Date(track.created_at).toDateString() }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row class="mt-4" v-if="tracking.tracking.length <= 0">
        <v-alert
          class="text-center"
          outlined
          color="border"
          style="width: 100%; color: #343235"
        >
          <span style="color: #343235">{{ $t("b4me.no_tracking") }}</span>
        </v-alert>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    tracking: null,
  }),

  created() {
    this.getOrders();
  },

  methods: {
    getOrders() {
      this.$http
        .get("/buy4me?store_id=" + this.$store.state.store_id)
        .then(({ data }) => {
          this.tracking = data.orders.filter((order) => {
            if (order.id == this.$route.params.id) return order;
          })[0];
        });
    },

    async viewOrder(invoice) {
      await this.$store.dispatch("setInvoice", invoice);
      this.$router.push({
        name: "Order Invoice",
        params: { id: invoice.order_id },
      });
    },
  },
};
</script>
