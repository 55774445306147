<template>
  <div class="home">
    <v-container class="my-8">
      <v-row class="mb-4">
        <p
          style="
            font-weight: bold;
            font-size: 16px;
            color: #343235;
            padding: 0;
            margin: 0;
          "
        >
          {{ $t("account.nav_invoice") }}
        </p>
      </v-row>
      <v-row>
        <Warehouse />
      </v-row>
      <v-row>
        <v-card
          class="mb-4 px-4 py-4"
          v-for="invoice in invoices"
          :key="invoice.id"
          style="background-color: #fff; width: 100%"
          flat
          rounded="2"
          elevation="2"
        >
          <v-row>
            <v-col cols="5">
              <span
                style="
                  font-size: 10px;
                  font-weight: 600;
                  display: block;
                  color: rgba(0, 0, 0, 0.6);
                "
                >{{ $t("account.order_id_in") }}</span
              >
              <h3 class="mt-2">#{{ invoice.order_id }}</h3>
              <span
                style="
                  font-size: 10px;
                  font-weight: 500;
                  display: block;
                  margin-top: 10px;
                "
                >{{ new Date(invoice.created_at).toDateString() }}</span
              >
            </v-col>
            <v-col cols="5">
              <span
                style="
                  font-size: 10px;
                  font-weight: 600;
                  display: block;
                  color: rgba(0, 0, 0, 0.6);
                "
                >{{ $t("account.invoice_cost_due") }}</span
              >
              <h3 class="mt-2">
                {{ invoice.total_shipping_price_kw.toFixed(3) }}
                <span>KWD</span>
              </h3>
              <span
                v-if="invoice.status == 'paid'"
                style="
                  font-size: 10px;
                  font-weight: 600;
                  display: flex;
                  margin-top: 8px;
                  align-items: center;
                "
                ><div
                  style="
                    height: 8px;
                    width: 8px;
                    background-color: #4caf50;
                    border-radius: 50%;
                  "
                ></div>
                <span class="mx-2">{{
                  $t("account.invoice_paid").toUpperCase()
                }}</span>
              </span>
              <span
                v-if="invoice.status == 'pending'"
                style="
                  font-size: 10px;
                  font-weight: 600;
                  display: flex;
                  margin-top: 8px;
                  align-items: center;
                "
                ><div
                  style="
                    height: 8px;
                    width: 8px;
                    background-color: orange;
                    border-radius: 50%;
                  "
                ></div>
                <span class="mx-2">{{
                  $t("account.invoice_pending").toUpperCase()
                }}</span>
              </span>
              <span
                v-if="invoice.status == 'refunded'"
                style="
                  font-size: 10px;
                  font-weight: 600;
                  display: flex;
                  margin-top: 8px;
                  align-items: center;
                "
                ><div
                  style="
                    height: 8px;
                    width: 8px;
                    background-color: red;
                    border-radius: 50%;
                  "
                ></div>
                <span class="mx-2">{{
                  $t("account.invoice_refunded").toUpperCase()
                }}</span>
              </span>
            </v-col>
            <v-col
              cols="2"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <img
                @click="viewInvoice(invoice)"
                v-if="$i18n.locale == 'en'"
                src="../assets/Icons/arrow_right.svg"
              />
              <img
                @click="viewInvoice(invoice)"
                v-if="$i18n.locale == 'ar'"
                src="../assets/Icons/arrow_left.svg"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-row>
      <!-- <v-row class="mb-8">
        <v-col>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">{{ $t("account.invoice_date") }}</th>
                  <th class="text-center">{{ $t("account.order_id") }}</th>
                  <th class="text-center">{{ $t("account.gateway") }}</th>
                  <th class="text-center">{{ $t("account.invoice_cost") }}</th>
                  <th class="text-center">
                    {{ $t("account.invoice_status") }}
                  </th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="invoice in invoices" :key="invoice.id">
                  <td class="text-center">
                    {{ new Date(invoice.created_at).toDateString() }}
                  </td>
                  <td class="text-center">{{ invoice.order_id }}</td>
                  <td class="text-center">{{ invoice.gateway.name }}</td>
                  <td class="text-center">
                    {{ invoice.total_shipping_price.currency }}
                    {{ invoice.total_shipping_price.amount }}
                  </td>
                  <td class="text-center">{{ invoice.status }}</td>
                  <td class="text-center">
                    <v-btn
                       @click="viewInvoice(invoice)"
                      color="secondary"
                      outlined
                      >{{ $t("account.invoice_receipt") }}</v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row> -->
      <v-row class="mt-4" v-if="invoices.length <= 0">
        <v-alert
          class="text-center"
          outlined
          color="border"
          style="width: 100%; color: #343235"
        >
          <span style="color: #343235">{{ $t("account.invoice_none") }}</span>
        </v-alert>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Warehouse from "@/components/Warehouse.vue";

export default {
  components: {
    Warehouse,
  },
  name: "Invoices",
  data: () => ({
    invoices: [],
  }),

  created() {
    this.getOrders();
    this.checkPendingList();
  },

  methods: {
    getOrders() {
      this.$http
        .get("/invoices/warba?store_id=" + this.$store.state.store_id)
        .then(({ data }) => {
          this.invoices = data.invoices;
        });
    },

    async viewInvoice(invoice) {
      await this.$store.dispatch("setInvoice", invoice);
      this.$router.push({
        name: "Order Invoice",
        params: { id: invoice.order_id },
      });
    },

    checkPendingList() {
      this.$http.get("/received/warba_pending_list").then(({ data }) => {
        let number = 0;
        number += data.orders.length;
        number += data.buy4me.length;
        this.postPaymentCount(number);
      });
    },
    postPaymentCount(count) {
      if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.pending_payments_count != undefined) {
          console.log("2");
          window.webkit.messageHandlers.pending_payments_count.postMessage(
            count
          );
        }
      }
      if (window.pending_payments_count != undefined) {
        window.pending_payments_count.postMessage(count);
      }
    },
  },
};
</script>
