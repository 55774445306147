<template>
  <div class="home">
    <v-container class="my-8">
      <v-row class="mb-4">
        <p
          style="
            padding: 0;
            margin: 0;
            font-weight: bold;
            font-size: 16px;
            color: #343235;
          "
        >
          {{ $t("account.unshipped_packages") }}
        </p>
      </v-row>

      <v-row class="mb-4">
        <Warehouse />
      </v-row>
      <div v-if="!orders.length <= 0">
        <v-row class="mb-4">
          <v-select
            return-object
            v-model="order"
            :items="orders"
            item-text="id"
            :label="$t('account.order_id')"
            outlined
            hide-details="true"
            @change="resetState"
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              <v-chip
                :color="data.item.status == 'pending' ? 'orange' : 'accent'"
                class="mx-1"
              >
                <span style="color: white">{{
                  $t("custom." + data.item.status)
                }}</span></v-chip
              >
              - {{ data.item.id }}
              <v-chip
                style="color: white"
                v-if="data.item.dg_order"
                x-small
                color="error"
                ><span style="color: white">DG</span></v-chip
              >
            </template>
            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              <v-chip
                style="color: white"
                :color="data.item.status == 'pending' ? 'orange' : 'accent'"
                class="mx-1"
              >
                <span style="color: white">{{
                  $t("custom." + data.item.status)
                }}</span></v-chip
              >
              - {{ data.item.id }}
            </template>
          </v-select>
        </v-row>
        <v-row class="mt-4" v-if="order.dg_order">
          <v-alert
            color="error"
            style="color: white; width: 100%"
            class="text-center mb-0"
          >
            <h4 style="color: white">{{ $t("custom.dangerous_goods") }}</h4>
          </v-alert>
        </v-row>
        <v-row>
          <v-col
            style="padding-right: 0; padding-left: 0"
            v-for="pkg in order._packages"
            :key="pkg.id"
            md="3"
          >
            <Package :info="pkg" :no_edit="pendingWarbaPayment" :isModalVisible.sync="isModalVisible"/>
          </v-col>
        </v-row>

        <v-alert
          rounded="0"
          class="mb-4"
          v-if="
            order.status == 'pending' && order.prepare_shipment_status != 'sent' && !isModalVisible"
          color="border"
          style="margin-left: -28px; margin-right: -28px"
        >
          <v-row>
            <v-col cols="1"
              ><img src="../assets/Icons/warning.svg" alt="!"
            /></v-col>
            <v-col cols="11" v-if="!isModalVisible">
              {{ $t("account.request_consolidate_message") }}<br />
              <v-dialog v-model="prepare_dialog" persistent max-width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="accent"
                    elevation="0"
                    :loading="loader"
                    v-bind="attrs"
                    v-on="on"
                    class="mt-2"
                    >{{ $t("account.request_consolidate") }}</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title class="headline">
                    {{ $t("custom.prepare_title") }}
                  </v-card-title>
                  <v-card-text>
                    {{ $t("custom.prepare_content") }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-row class="my-4">
                      <v-btn
                        style="height: 42px"
                        class="my-2"
                        block
                        color="secondary"
                        @click="prepare_dialog = false"
                      >
                        {{ $t("custom.prepare_no") }}
                      </v-btn>
                      <v-btn
                        :loading="loader"
                        class="my-2"
                        block
                        color="primary"
                        @click="prepareMyShipment"
                      >
                        {{ $t("custom.prepare_yes") }}
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-alert>

        <v-alert
          rounded="0"
          class="mb-16 mt-4"
          v-if="pendingWarbaPayment && !isModalVisible"
          color="border"
          style="margin-left: -28px; margin-right: -28px"
        >
          <v-row>
            <v-col cols="1"
              ><img src="../assets/Icons/warning.svg" alt="!"
            /></v-col>
            <v-col cols="11">{{ $t("account.warba_ready") }}</v-col>
          </v-row>
        </v-alert>

        <v-alert
          rounded="0"
          class="mb-8"
          v-if="packagesPriceNeed && !isModalVisible"
          color="border"
          style="margin-left: -28px; margin-right: -28px"
        >
          <v-row>
            <v-col cols="1"
              ><img src="../assets/Icons/warning.svg" alt="!"
            /></v-col>
            <v-col cols="11"> {{ $t("account.needs_resolve") }} </v-col>
          </v-row>
        </v-alert>
        <!-- pending condition -->
        <div
          v-if="
            order.status != 'pending' &&
            !packagesPriceNeed &&
            !pendingWarbaPayment
          "
        >
          <v-row
            class="mb-4"
            align="center"
            style="
              background-color: #eeeeee;
              margin-left: -28px;
              margin-right: -28px;
            "
          >
            <v-col class="text-center">
              <h3 class="my-4">{{ $t("account.package_dimensions") }}</h3>
              <v-simple-table class="my-4">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="font-size: 10px">
                        {{ $t("account.d_length") }}
                      </th>
                      <th style="font-size: 10px">
                        {{ $t("account.d_width") }}
                      </th>
                      <th style="font-size: 10px">
                        {{ $t("account.d_height") }}
                      </th>
                      <th style="font-size: 10px">
                        {{ $t("account.d_weight") }}
                      </th>
                      <th style="font-size: 10px">
                        {{ $t("account.volumetric_weight") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in order.dimensions" :key="item.id">
                      <td style="font-size: 10px">
                        {{ item.length
                        }}{{
                          order.store["customizations"]["measurement_unit"][
                            "code"
                          ]
                        }}
                      </td>
                      <td style="font-size: 10px">
                        {{ item.width
                        }}{{
                          order.store["customizations"]["measurement_unit"][
                            "code"
                          ]
                        }}
                      </td>
                      <td style="font-size: 10px">
                        {{ item.height
                        }}{{
                          order.store["customizations"]["measurement_unit"][
                            "code"
                          ]
                        }}
                      </td>
                      <td style="font-size: 10px">
                        {{ item.weight
                        }}{{
                          order.store["customizations"]["weight_unit"]["code"]
                        }}
                      </td>
                      <td style="font-size: 10px">
                        {{ item.vweight
                        }}{{
                          order.store["customizations"]["weight_unit"]["code"]
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-if="vWeight > weight">
                <h4>
                  {{ $t("account.package_weight_total") }} {{ order.weight }}
                  {{ order.store["customizations"]["weight_unit"]["code"] }}
                </h4>
                <p>
                  {{ $t("account.vweight_desc") }}
                </p>
              </div>
              <div v-if="vWeight < weight">
                <h4>
                  {{ $t("account.package_weight_total") }} {{ order.weight }}
                  {{ order.store["customizations"]["weight_unit"]["code"] }}
                </h4>
                <p>
                  {{ $t("account.weight_desc") }}
                </p>
              </div>
            </v-col>
          </v-row>

          <v-row class="mb-4" align="center" v-if="order.voucher == null">
            <v-alert
              color="rgba(0, 0, 0, 0.38)"
              outlined
              style="text-align: center"
            >
              {{ $t("account.voucher_redeem_msg") }}<br />
              <v-text-field
                class="mt-4"
                :label="$t('account.voucher_code')"
                outlined
                hide-details="true"
                v-model="voucher"
              ></v-text-field>
              <v-btn
                :loading="loader"
                class="mt-4"
                block
                elevation="0"
                height="48"
                color="accent"
                @click="useVoucher"
              >
                {{ $t("account.voucher_redeem") }}
              </v-btn>
            </v-alert>
          </v-row>

          <v-row align="center">
            <v-col style="padding-right: 0; padding-left: 0">
              <div class="panel-step">
                {{ $t("account.ship_method") }}
              </div>
              <v-radio-group
                class="px-4"
                style="
                  border: thin solid rgba(0, 0, 0, 0.38) !important;
                  border-radius: 3px;
                "
                v-model="shippingMethod"
              >
                <template v-slot:label> </template>
                <v-radio
                  v-for="courier in couriers"
                  v-bind:key="courier.id"
                  :value="courier"
                >
                  <template v-slot:label>
                    <div
                      :class="
                        $store.state.lang == 'ar'
                          ? 'py-4 text-right'
                          : 'py-4 text-left'
                      "
                      style="
                        display: block;
                        font-size: 16px;
                        background: transparent !important;
                        border-bottom: thin solid rgba(0, 0, 0, 0.38) !important;
                        width: 100%;
                        text-align: right;
                      "
                    >
                      <div>
                        <img
                          style="width: 48px; height: 20px"
                          :src="courier.courier_info.image_path"
                          :alt="courier.courier_info.name_pretty"
                          class="mx-1"
                        />
                        <!-- <div
                          class="mx-1"
                          style="
                            font-weight: normal;
                            font-size: 10px;
                            color: black;
                          "
                        >
                          ( {{ courier.courier_info.working_days_from }} to
                          {{ courier.courier_info.working_days_to }} Working
                          days )
                        </div> -->
                        <div style="font-size: 12px">
                          ( {{ $t("front.from") }}
                          {{ courier.courier_info.working_days_from }}
                          {{ $t("front.to") }}

                          {{ courier.courier_info.working_days_to }}
                          {{ $t("front.working_days") }}
                          ):
                          <span class="boldnes" style="font-size: 12px"
                            >{{ courier.price_kw_no_dg.toFixed(3) }}KWD</span
                          >

                          <span
                            v-if="
                              order.dg_order &&
                              courier.courier_info.dangerous_goods_price != null
                            "
                            class="boldnes"
                            style="font-size: 12px"
                          >
                            +
                            {{
                              courier.courier_info.dangerous_goods_price_kw.toFixed(
                                2
                              )
                            }}KWD {{ $t("account.dangerous_goods") }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row
            align="center"
            v-if="!useCredits && credits.credits != 0 && shippingMethod"
          >
            <v-col style="padding-right: 0; padding-left: 0">
              <v-alert
                color="rgba(0, 0, 0, 0.38)"
                outlined
                style="text-align: center"
              >
                <p class="text-center" style="font-weight: 700">
                  {{ credits.credits_us.currency.symbol
                  }}{{ credits.credits_us.amount_og }}
                </p>
                {{ $t("custom.free_credits") }}
                <v-btn
                  class="mt-4"
                  block
                  height="48"
                  color="accent"
                  elevation="0"
                  @click="useCredits = true"
                >
                  {{ $t("custom.use_it") }}
                </v-btn>
              </v-alert>
            </v-col>
          </v-row>

          <v-row v-if="shippingMethod">
            <v-col style="padding-right: 0; padding-left: 0">
              <div class="panel-step">
                {{ $t("custom.payment_invoice") }}
              </div>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-right' : 'text-left'
                        "
                        style="padding: 0"
                      >
                        {{ $t("account.shipping_fee") }}
                      </th>
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-left' : 'text-right'
                        "
                        style="padding: 0"
                      >
                        {{ shippingMethod.price_kw_no_dg.toFixed(3) }} KWD
                      </th>
                    </tr>
                    <tr v-if="shippingMethod.courier_info.id == 6">
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-right' : 'text-left'
                        "
                        style="padding: 0"
                      >
                        {{ $t("account.clearance") }}
                      </th>
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-left' : 'text-right'
                        "
                        style="padding: 0"
                      >
                        {{ clearance }} KWD
                      </th>
                    </tr>
                    <tr v-if="shippingMethod.courier_info.id == 5">
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-right' : 'text-left'
                        "
                        style="padding: 0"
                      >
                        {{ $t("account.clearance") }}
                      </th>
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-left' : 'text-right'
                        "
                        style="padding: 0"
                      >
                        {{ clearance }} KWD
                      </th>
                    </tr>
                    <tr
                      v-if="
                        order.dg_order &&
                        shippingMethod.courier_info.dangerous_goods_price !=
                          null
                      "
                    >
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-right' : 'text-left'
                        "
                        style="padding: 0"
                      >
                        {{ $t("account.dangerous_goods") }}
                      </th>
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-left' : 'text-right'
                        "
                      >
                        {{
                          shippingMethod.courier_info.dangerous_goods_price_kw.toFixed(
                            2
                          )
                        }}
                        KWD
                      </th>
                    </tr>
                    <tr v-for="fee in customFees" v-bind:key="fee.id">
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-right' : 'text-left'
                        "
                        style="padding: 0"
                      >
                        {{ fee.name_local }}
                      </th>
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-left' : 'text-right'
                        "
                        style="padding: 0"
                      >
                        {{ fee.price_kw }} KWD
                      </th>
                    </tr>
                    <tr v-if="order.voucher">
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-right' : 'text-left'
                        "
                        style="padding: 0"
                      >
                        Voucher({{ order.voucher.name }})
                      </th>
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-left' : 'text-right'
                        "
                      >
                        {{ voucherMinus }} KWD
                      </th>
                    </tr>
                    <tr v-if="useCredits">
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-right' : 'text-left'
                        "
                        style="padding: 0"
                      >
                        {{ $t("account.credits") }}
                      </th>
                      <th
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                        "
                      >
                        <div
                          :class="
                            $store.state.lang == 'ar'
                              ? 'text-left'
                              : 'text-right'
                          "
                        >
                          - {{ credits_kw }} KWD
                        </div>
                        <div>
                          <v-btn x-small icon @click="useCredits = false"
                            ><v-icon small color="red"
                              >fa fa-trash</v-icon
                            ></v-btn
                          >
                        </div>
                      </th>
                    </tr>
                    <tr style="background: #eeeeee">
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-right' : 'text-left'
                        "
                        style="padding: 0"
                      >
                        {{ $t("account.total_cost") }}:
                      </th>
                      <th
                        :class="
                          $store.state.lang == 'ar' ? 'text-left' : 'text-right'
                        "
                        style="padding: 0"
                      >
                        {{ totalKw }} KWD
                      </th>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialog"
          >
            <template>
              <v-card>
                <v-card-text class="py-2">
                  <div class="text-center">
                    <svg
                      style="width: 48px; height: 48px"
                      class="ft-green-tick"
                      xmlns="http://www.w3.org/2000/svg"
                      height="100"
                      width="100"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <circle
                        class="circle"
                        fill="#35D37A"
                        cx="24"
                        cy="24"
                        r="22"
                      />
                      <path
                        class="tick"
                        fill="none"
                        stroke="#FFF"
                        stroke-width="6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="10"
                        d="M14 27l5.917 4.917L34 17"
                      />
                    </svg>
                    <!-- <div
                  class="text-center"
                  style="font-size: 20px; font-weight: bold"
                >
                  Success
                </div> -->
                  </div>
                  <div class="text-center" style="font-size: 16px">
                    {{ $t("account.alert_success") }}
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="accent" block outlined @click="dialogYes">{{
                    $t("account.alert_done")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <v-row class="mb-8" v-if="shippingMethod">
            <v-col style="padding-right: 0; padding-left: 0">
              <v-btn
                :loading="loader"
                @click="payOrder"
                block
                elevation="0"
                height="48"
                color="accent"
                class="mt-2"
                >{{ $t("account.warba_init") }}</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row class="mt-4" v-if="orders.length <= 0">
        <v-alert
          class="text-center"
          outlined
          color="border"
          style="width: 100%; color: #343235"
        >
          <span style="color: #343235">{{ $t("account.no_packages1") }}</span>
        </v-alert>
      </v-row>
    </v-container>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.0.4/socket.io.js"></script>
<script>
// @ is an alias to /src
import Package from "@/components/Package.vue";
import Warehouse from "@/components/Warehouse.vue";

export default {
  name: "Home",
  components: {
    Package,
    Warehouse,
  },
  data: () => ({
    loader: false,
    dialog: false,
    order: null,
    prepare_dialog: false,
    voucher: null,
    shippingMethod: null,
    useCredits: false,
    options: null,
    orders: [],
    credits: null,
    clearance: null,
    totalKw: null,
    voucherMinus: null,
    credits_kw: null,
    isModalVisible: false
}),

  computed: {
    couriers() {
      return this.order.fees.filter((fee) => {
        if (fee.courier_info != null) {
          return fee;
        }
      });
    },

    weight() {
      let weight = 0;
      this.order.dimensions.forEach((d) => {
        weight += d.weight;
      });
      return weight;
    },

    vWeight() {
      let weight = 0;
      this.order.dimensions.forEach((d) => {
        weight += d.vweight;
      });
      return weight;
    },

    customFees() {
      return this.order.fees.filter((fee) => {
        if (fee.courier_info == null) {
          return fee;
        }
      });
    },

    //decide whther all packages have prices or not :)
    // todo: make a condition for that .
    packagesPriceNeed() {
      let needPrice = false;
      this.order.packages.forEach((pkg) => {
        if (pkg.price_og == 0) {
          needPrice = true;
        }
      });
      return needPrice;
    },

    pendingWarbaPayment() {
      let pending = false;
      this.order.fees.forEach((fee) => {
        if (fee.warba_status == "pending") {
          pending = true;
        }
      });
      return pending;
    },

    // total() {
    //   let total = 0;
    //   total += this.shippingMethod.amount;
    //   if (
    //     this.shippingMethod.courier_info.id == 5 ||
    //     this.shippingMethod.courier_info.id == 6
    //   ) {
    //     total += 700;
    //   }

    //   if(this.customFees.length > 0){
    //     this.customFees.forEach((fee) =>{
    //       total = total + fee.amount;
    //     })
    //   }

    //   return total / 100;
    // },

    // voucherMinus() {
    //   let voucher = 0;
    //   voucher = this.totalKw * (this.order.voucher.percentage / 100);
    //   console.log(this.order.voucher.percentage / 100);
    //   // Create our number formatter.
    //   // var formatter = new Intl.NumberFormat("en-US", {
    //   //   style: "currency",
    //   //   currency: "USD",
    //   // });

    //   return "-" + voucher;
    // },
  },

  mounted() {
    let socket = io("https://postshipper.com:3445");
    setTimeout(() => {
      socket.on("payment-channel-" + this.$store.state.user.id, () => {
        // console.log('socket ping');
        this.dialogYes();
      });
    }, 1000);
  },

  watch: {
    shippingMethod() {
      this.totalAfter();
    },
    useCredits() {
      this.totalAfter();
    },
  },

  created() {
    this.getOrders();
    this.checkPendingList();
  },

  methods: {
    async totalAfter() {
      let total = 0;
      total += this.shippingMethod.amount_og;
      if (
        this.shippingMethod.courier_info.id == 5 ||
        this.shippingMethod.courier_info.id == 6
      ) {
        total += 700;
      }

      if (this.customFees.length > 0) {
        this.customFees.forEach((fee) => {
          total = total + fee.amount_og;
        });
      }

      if (this.order.voucher != null) {
        let voucherM = 0;
        if (this.order.voucher.percentage != null) {
          voucherM = total * (this.order.voucher.percentage / 100);
        } else {
          voucherM = this.order.voucher.value;
        }
        let vM = "-" + (await this.converterR("$" + voucherM / 100));
        console.log(vM);
        this.voucherMinus = vM;
        total = total - voucherM;
      }
      if (this.useCredits) {
        if (this.credits.credits >= total) {
          total = 0;
        } else {
          total = total - this.credits.credits;
        }
      }

      // if (this.order.dg_order) {
      //   total = total + this.shippingMethod.courier_info.dangerous_goods_price*100;
      // }

      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return this.converter(formatter.format(total / 100));
    },
    converter(total) {
      this.$http
        .post("/received/convert_to_kw", {
          order_id: this.order.id,
          amounts: [total, "$7", this.credits.credits_us.amount],
        })
        .then(({ data }) => {
          this.clearance = data[1];
          this.credits_kw = data[2];

          this.totalKw = parseFloat(data[0]).toFixed(3);
        });
    },

    converterR(total) {
      this.$http
        .post("/received/convert_to_kw", {
          order_id: this.order.id,
          amounts: [total],
        })
        .then(({ data }) => {
          console.log(data);
          return data[0];
        });
    },

    getOrders() {
      this.$http
        .get("/received/orders?store_id=" + this.$store.state.store_id)
        .then(({ data }) => {
          this.orders = data.orders;
          this.order = data.orders[0];
          this.credits = data.wallet;
        });
    },

    prepareMyShipment() {
      this.loader = true;
      this.$http
        .post("/received/prepare", { order_id: this.order.id })
        .then(() => {
          this.loader = false;
          this.prepare_dialog = false;
          this.getOrders();
        });
    },
    useVoucher() {
      if (this.voucher != null) {
        this.loader = true;
        this.$http
          .put("/received/redeem", {
            order_id: this.order.id,
            code: this.voucher,
            store_id: this.$store.state.store_id,
          })
          .then(() => {
            this.loader = false;
            this.getOrders();
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },

    resetState() {
      this.loader = false;
      this.dialog = false;
      this.prepare_dialog = false;
      this.voucher = null;
      this.shippingMethod = null;
      this.useCredits = false;
      this.options = null;
      this.clearance = null;
      this.totalKw = null;
    },

    payOrder() {
      this.loader = true;
      this.$http
        .post("/received/warba_init", {
          order_id: this.order.id,
          useCredits: this.useCredits,
          shipping_method: this.shippingMethod.id,
          // options: [
          //   {
          //     name: "insurance_goods",
          //   },
          // ],
        })
        .then(() => {
          this.loader = false;
          this.dialog = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },

    dialogYes() {
      this.checkPendingList();
      this.getOrders();
      this.dialog = false;
    },

    checkPendingList() {
      this.$http.get("/received/warba_pending_list").then(({ data }) => {
        let number = 0;
        number += data.orders.length;
        number += data.buy4me.length;
        this.postPaymentCount(number);
      });
    },
    postPaymentCount(count) {
      if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.pending_payments_count != undefined) {
          console.log("2");
          window.webkit.messageHandlers.pending_payments_count.postMessage(
            count
          );
        }
      }
      if (window.pending_payments_count != undefined) {
        window.pending_payments_count.postMessage(count);
      }
    },
  },
};
</script>

<style scoped>
@supports (animation: grow 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards) {
  .tick {
    stroke-opacity: 0;
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
  }

  .circle {
    fill-opacity: 0;
    stroke: #35d37a;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }
}

@keyframes grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #35d37a;
  }
}

@keyframes draw {
  0%,
  100% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

:root {
  --theme-color: var(--color-purple);
}

body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
