import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    store_id: parseInt(localStorage.getItem('store_id')) || 1,
    token: localStorage.getItem('token') || null,
    // token: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3IiwianRpIjoiYzZmMDBhNzk1NTJkZGU0YjZjYmIwNjVlN2EzZWY2YWM1Mjc2OWRlODZjZTJjOTc4MmEzN2M0YTA4ZGYyOTNlMGY1Y2NiNThkNjQ0YWEwZTkiLCJpYXQiOjE3MjY0MDE0MzMuNTI3OTI2LCJuYmYiOjE3MjY0MDE0MzMuNTI3OTMxLCJleHAiOjE3NTc5Mzc0MzMuMjgwNjIsInN1YiI6IjM0NTAxIiwic2NvcGVzIjpbXX0.AYntfpc2DymyaklEZPef3J4VbPiLW30my27NtKB0MEByDCRGe9jbja-URTz4AsirrfWZRRcm6wU2XTYHXHxmLhVAyQDeAjb4K6YN5JzqXxKUHdLHoqG-CXShgC6z2AqEbZyEDF-VfC0vX4XQRLcURszlAf6rMVp2k75zjDQ-Ir6Ime-ps3_sRszFZ9SGEfRkEnJ1n6dEkUtAbSFaH2UmpXHUB1_cqSDVT1MJePFkqr49eiqq-1dTUmSvRzL2030timIEfeAGb9Two3k2PpK-nIYqryzqmSpXi7-08kF68xkvoIyNoa5Mzcpje5F33XiBW57VQmKu-d-2FFpazZGsDuSyLD2dPGnBPxvibRofeTsqY3jcbRsDiVBlQJRjmE6WO0tU1nvBwgN7XJtEru8OqwymELlyow4NCCcWEEaMZ9Tum-ENzV6syBqnWcfV2SrYTw9tKwnel0lV5mc6wupnMZ3nk31Fms4eXJfj7sxJeZAqY3B0Xs-z_82V_rGcek3FNgZC-8b0y9qKCC0oP_km5hPD9twykv3o541KcVqF5P_xOPQlkcwOBoD5uJeb_d0vEoctIeL0uenXJt-hdg1P6sVGcRwqcS__H0sxwnimhDYOde-ESn8X384_1cbDT0DlWG0PSS53RoOMqcH6QKpnWbuLwCjW7V1R4qPsA15unus",
    // URL: 'http://localhost:8000/api/v1',
    URL: 'https://api.postshipper.com/api/v1',
      // URL: 'http://139.162.212.160:8091/api/v1',
    lang: localStorage.getItem('lang') || 'en',
    notfications: [],
    selectedShippedOrder: null,
    invoice: null,
    user: null,
  },
  getters: {
    loggedIn: (state) => state.token !== null,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    DESTROY_TOKEN(state) {
      state.token = null;
    },

    SET_STORE_ID(state, store_id) {
      state.store_id = store_id;
      },

    SET_NOTFICATIONS(state, notfications) {
      state.notfications = notfications;
    },

    SET_USER(state, user) {
      state.user = user;
    },

    SET_SHIPPED_ORDER(state, selectedShippedOrder) {
      state.selectedShippedOrder = selectedShippedOrder;
    },

    SET_INVOICE(state, invoice) {
      state.invoice = invoice;
    },

    SET_LANG(state, lang) {
      state.lang = lang;
    },
  },
  actions: {
    login({commit}) {
      return commit("SET_TOKEN", localStorage.getItem('token'));
    },

    fetchNotfications({commit}, data) {
          return commit("SET_NOTFICATIONS", data);
    },

    setStore({commit}, data) {
      localStorage.setItem('store_id', data.store_id);
       commit("SET_STORE_ID", data.store_id);
       return location.reload();
    },

    setLang({commit}, lang) {
      localStorage.setItem('lang', lang);
       commit("SET_LANG", lang);
       return location.reload();
    },

    fetchUser({commit}, data) {
          return commit("SET_USER", data);
    },

    setShippedOrder({commit}, data) {
       return commit("SET_SHIPPED_ORDER", data);
    },

    setInvoice({commit}, data) {
       return commit("SET_INVOICE", data);
    },
  },
  modules: {}
});
