<template>
  <v-container class="my-8">
    <v-row class="mb-4">
      <p
        style="
          padding: 0;
          margin: 0;
          font-weight: bold;
          font-size: 16px;
          color: #343235;
        "
      >
        {{ $t("account.nav_orders") }}
      </p>
    </v-row>
    <v-row>
      <Warehouse />
    </v-row>
    <v-row class="mt-4" v-if="orders.length <= 0">
      <v-alert
        class="text-center"
        outlined
        color="border"
        style="width: 100%; color: #343235"
      >
        <span style="color: #343235">{{ $t("account.no_packages1") }}</span>
      </v-alert>
    </v-row>
    <v-row>
      <v-col
        style="padding-right: 0; padding-left: 0"
        v-for="(item, index) in orders"
        :key="index"
        md="3"
      >
        <PackageShipped :info="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Warehouse from "@/components/Warehouse.vue";
import PackageShipped from "@/components/PackageShipped.vue";

export default {
  components: {
    Warehouse,
    PackageShipped,
  },
  data: () => ({
    orders: [],
  }),

  created() {
    this.getOrders();
    this.checkPendingList();
  },

  methods: {
    getOrders() {
      this.$http
        .get("/shipped/orders/warba?store_id=" + this.$store.state.store_id)
        .then(({ data }) => {
          this.orders = data.orders;
        });
    },

    checkPendingList() {
      this.$http.get("/received/warba_pending_list").then(({ data }) => {
        let number = 0;
        number += data.orders.length;
        number += data.buy4me.length;
        this.postPaymentCount(number);
      });
    },
    postPaymentCount(count) {
      if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.pending_payments_count != undefined) {
          console.log("2");
          window.webkit.messageHandlers.pending_payments_count.postMessage(
            count
          );
        }
      }
      if (window.pending_payments_count != undefined) {
        window.pending_payments_count.postMessage(count);
      }
    },
  },
};
</script>
