<template>
  <div>
    <v-container class="my-8">
      <v-row>
        <p style="font-weight: bold; font-size: 16px; color: #343235">
          {{ $t("account.faq") }}
        </p>
      </v-row>
      <v-row>
        <Warehouse />
      </v-row>

      <v-row v-if="faqs">
        <v-expansion-panels>
          <v-expansion-panel
            class="mb-4"
            v-for="(faq, i) in faqs.faqs"
            :key="i"
          >
            <v-expansion-panel-header>
              <div v-if="$store.state.lang == 'ar'" v-html="faq.title_ar"></div>
              <div v-if="$store.state.lang == 'en'" v-html="faq.title_en"></div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div
                class="px-4"
                v-if="$store.state.lang == 'ar'"
                v-html="faq.content_ar"
              ></div>
              <div
                class="px-4"
                v-if="$store.state.lang == 'en'"
                v-html="faq.content_en"
              ></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Warehouse from "@/components/Warehouse.vue";

export default {
  components: {
    Warehouse,
  },
  data: () => ({
    store: null,
    faqs: null,
  }),

  created() {
    this.getStores();
    this.getFaqs();

    this.checkPendingList();
  },

  methods: {
    getStores() {
      this.$http.get("/stores").then(({ data }) => {
        this.store = data.stores.filter((store) => {
          if (store.id == this.$store.state.store_id) {
            return store;
          }
        })[0];
      });
    },
    getFaqs() {
      this.$http
        .get("/faq?store_id" + this.$store.state.store_id)
        .then(({ data }) => {
          this.faqs = data;
        });
    },

    checkPendingList() {
      this.$http.get("/received/warba_pending_list").then(({ data }) => {
        let number = 0;
        number += data.orders.length;
        number += data.buy4me.length;
        this.postPaymentCount(number);
      });
    },
    postPaymentCount(count) {
      if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.pending_payments_count != undefined) {
          console.log("2");
          window.webkit.messageHandlers.pending_payments_count.postMessage(
            count
          );
        }
      }
      if (window.pending_payments_count != undefined) {
        window.pending_payments_count.postMessage(count);
      }
    },
  },
};
</script>
