<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
      >
        <header
            class="modal-header"
            id="modalTitle"
        >
          <slot name="header">
            {{ $t("custom.add_Prices_To_Items") }}
          </slot>
          <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
          >
            x
          </button>
        </header>

        <section
            class="modal-body"
            id="modalDescription"
        >
          <slot name="body">
            <v-container class="">
              <v-row class="" v-for="item in items" :key="item.id" >
                <v-col md="6">
                            <v-text-field
                              class="custom-field"
                              type="text"
                              v-model="item.name"
                              label="Description"
                              outlined
                              readonly
                            ></v-text-field>
                </v-col>
                <v-col md="2">
                            <v-text-field
                              class="custom-field"
                              type="number"
                              v-model="item.quantity"
                              label="qty"
                              outlined
                              readonly
                            ></v-text-field>
                </v-col>
                <v-col md="3">
                            <v-text-field
                              class="custom-field"
                              type="number"
                              v-model="item.value"
                              label="Total Price"
                              outlined
                            ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer">
            <button
                type="button"
                class="btn-green add-price-btn"
                @click="addPrice"
                aria-label="Close modal"
            >
              {{ $t("custom.add_Prices") }}
            </button>
            <button
                type="button"
                class="btn-green"
                @click="close"
                aria-label="Close modal"
            >
              {{ $t("account.close") }}
            </button>
          </slot>

        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: ["items"],
  data: () => ({
    values : new Map([])
  }),
  methods: {
    close() {
      this.$emit('close');
    },
    mapToObject(map) {
      const obj = {};
      for (const [key, value] of map.entries()) {
        obj[key] = value;
      }
      return obj;
    },
    addPrice() {
      this.loader = true;
      for(let i = 0; i<this.items.length; i++)
      {
        this.values.set(this.items[i].id, this.items[i].value);
      }
      const mapObject = this.mapToObject(this.values);
      this.$http
          .put("/received/set_package_items_price", {
            items: mapObject,
          })
          .then(() => {
            this.loader = false;
            location.reload();
          });
    },
  },
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  display: block;
  z-index: 6;
  overflow: scroll;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 300px;
  margin: auto;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}

.custom-field{
 font-size: 12px !important;
  height: 60px !important;
}

.add-price-btn{
  background-color: #F47840!important;
  margin-bottom: 5px;
}
</style>