<template>
  <v-container class="my-8" style="padding: 0">
    <v-row class="mb-4">
      <v-col> {{ $t("account.order_id") }} {{ this.$route.params.id }} </v-col>
    </v-row>
    <v-card class="mb-4" style="padding: 10px">
      <v-row>
        <v-col sm="5">
          <div dir="ltr">
            <span style="font-size: 11px; display: block">{{
              invoice.store.name
            }}</span>
            <span style="font-size: 11px; display: block">{{
              invoice.store.address_line
            }}</span>
            <span style="font-size: 11px; display: block"
              >{{ invoice.store.city }}, {{ invoice.store.state }}
              {{ invoice.store.zip_code }}</span
            >
            <span style="font-size: 11px; display: block">{{
              invoice.store.phone
            }}</span>
          </div>
        </v-col>
        <v-col sm="2"> </v-col>
        <v-col sm="5">
          <div>
            <span style="font-size: 11px; display: block">{{
              new Date(invoice.created_at).toDateString()
            }}</span>
            <span style="font-size: 11px; display: block"
              >{{ $t("account.order_id") }}:{{ invoice.order_id }}</span
            >
            <span style="font-size: 11px; display: block"
              >{{ $t("account.shipping_method") }}:{{
                invoice.fees[0].courier_info.name_pretty
              }}</span
            >
            <span style="font-size: 11px; display: block"
              >Status:{{ invoice.status }}</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    {{ $t("account.package_value") }}
                  </th>
                  <th>
                    {{ $t("account.quantity") }}
                  </th>
                  <th class="text-center">
                    {{ $t("account.invoice_item") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in invoice.packages" :key="item.id">
                  <th>{{ item.price }}</th>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>{{ $t("account.shipping_fee") }}</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="item in invoice.fees" :key="item.id">
                  <th>{{ item.name }}</th>
                  <th>
                    {{
                      item.fixed_local_amount != null
                        ? item.fixed_local_amount.toFixed(3)
                        : item.price_kw_no_dg.toFixed(3)
                    }}
                    KWD
                  </th>
                </tr>
                <tr>
                  <th>
                    {{ $t("account.total_cost") }} :
                    {{ invoice.total_shipping_price_kw.toFixed(3) }} KWD
                  </th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>




<script>
import { mapState } from "vuex";

export default {
  computed: mapState({
    invoice: (state) => state.invoice,
  }),
};
</script>

<style lang="scss" scoped>
.boldnes {
  font-weight: 700;
}
</style>
