<template>
    <div>
        <div style="display: flex; justify-content:center; align-items:center; margin-top: 200px">
          <div class="text-center">
            <img width="200" src="https://www.postshipper.com/client/images/logo.png" alt="postshipper" >
            <v-alert class="text-center">
              Welcome to Postshipper
            </v-alert>
            <v-alert outlined class="text-center">
Please note that this service is only available for Warbabank users
            </v-alert>
          </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
