<template>
  <v-card class="mt-2" min-height="450" tile>
    <v-container>
      <v-row>
        <v-col cols="6">
          <div>
            <span class="lgrey--text" style="font-size: 12px">{{
              $t("account.order_id")
            }}</span>
            <span style="display: block; font-size: 20px">{{ info.id }}</span>
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <span class="lgrey--text" style="font-size: 12px">{{
              $t("account.shipped_at")
            }}</span>
            <span style="display: block; font-size: 20px">{{
              new Date(info.created_at).toDateString()
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div>
            <span class="lgrey--text" style="font-size: 12px">{{
              $t("account.order_cost")
            }}</span>
            <span style="display: block; font-size: 20px">{{
              info.duituable_with_currency
            }}</span>
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <span class="lgrey--text" style="font-size: 12px">{{
              $t("account.package_count")
            }}</span>
            <span style="display: block; font-size: 20px">{{
              info._packages.length
            }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-carousel height="235" hide-delimiters show-arrows-on-hover>
      <v-carousel-item v-for="(slide, i) in slides" :key="i">
        <v-img
          :src="info.consolidate_image"
          :lazy-src="info.consolidate_image"
          aspect-ratio="1"
          class="grey lighten-2"
        ></v-img>
      </v-carousel-item>
    </v-carousel>
    <!-- <v-img
      src="https://postshipper-images.eu-central-1.linodeobjects.com/images/TNj7GGb2iYLGKxWocYz0AXV8.jpg"
    ></v-img> -->

    <v-card-text>
      <div>
        {{ $t("account.order_status") }}:
        <span class="boldnes">{{ info.status_local }}</span>
      </div>
      <div>
        {{ $t("account.order_weight") }}:
        <span class="boldnes"
          >{{ info.weight }}
          {{ info.store.customizations.weight_unit.code }}</span
        >
      </div>
      <div>
        {{ $t("account.shipping_method") }}:
        <span class="boldnes">{{ info.courier_og.name_pretty }}</span>
      </div>
      <div>
        {{ $t("account.package_count") }}:
        <span class="boldnes">{{ info._packages.length }}</span>
      </div>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-btn elevation="0" @click="viewOrder(info)" color="primary">
        {{ $t("account.view_order") }}
      </v-btn>
      <v-btn
        elevation="0"
        :to="'/shipped/order/track/' + info.id"
        color="accent"
      >
        {{ $t("account.track_order") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["info"],
  data: () => ({
    // data props goes here
    overlay: false,
    zIndex: 0,
    tab: null,
    colors: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
  }),

  methods: {
    async viewOrder(order) {
      await this.$store.dispatch("setShippedOrder", order);
      this.$router.push({ name: "Order", params: { id: order.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.boldnes {
  font-weight: 700;
}
</style>
